import React, { FunctionComponent, useRef } from "react";
import { graphql } from "gatsby";
import { ContentstackVideo } from "../../../data/types/Contentstack";
import SectionTitle from "../../general/SectionTitle/SectionTitle";
import DetailLine from "../../general/borders/DetailLine/DetailLine";
import styles from "./GameVideoData.module.scss";
import { Video } from "../../general/Video/Video";
import { animateCircle } from "./animations";

import { useScrollObserver } from "../../../hooks/useScrollObserver";
export interface GameVideoDataProps {
  titleParts: Array<string>;
  description: string;
  videos: Array<ContentstackVideo>;
}

export const GameVideoData: FunctionComponent<GameVideoDataProps> = ({ titleParts, description, videos: [video] }) => {
  const videoWrapperRef = useRef<HTMLDivElement | null>(null);
  const observeRef = useScrollObserver<HTMLDivElement | null>(animateCircle, videoWrapperRef, { threshold: 0 });

  return (
    <section ref={observeRef} className={`container ${styles.gameVideoData}`}>
      <div className={`content-wrapper light ${styles.contentWrapper}`}>
        <div className={styles.copyWrapper}>
          <DetailLine className={styles.detailLine} variation="top">
            <SectionTitle className={`heading-08" ${styles.title}`} titleParts={titleParts} />
          </DetailLine>
          <p className={`copy-01 ${styles.description}`}>{description}</p>
        </div>
        <div ref={videoWrapperRef} className={styles.videoWrapper}>
          <div className={styles.videoTestWrapper}>
            <Video
              className={styles.gameVideo}
              source={{ mp4Url: video.video.file.url, posterUrl: video.posterImage.url }}
            />
          </div>
          <svg className={styles.whiteDiamond} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.21 93.21">
            <g data-name="Layer 2">
              <g data-name="Layer 1">
                <path className={styles.cls2} d="M46.6.004l46.605 46.605L46.6 93.215-.005 46.609z" />
              </g>
            </g>
          </svg>
          <svg className={styles.borderDiamond} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.33 60.33">
            <g data-name="Layer 2">
              <g data-name="Layer 1">
                <path className={styles.cls1} d="M30.17.002l30.165 30.165L30.17 60.332.005 30.167z" />
                <path className={styles.cls2} d="M30.17 22.233l7.934 7.934L30.17 38.1l-7.934-7.934z" />
              </g>
            </g>
          </svg>
          <svg className={styles.borderOuterCircle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 586.21 586.21">
            <defs>
              <mask id="mask">
                <circle
                  cx="295.11"
                  cy="295.11"
                  r="290.11"
                  stroke="#fff"
                  strokeWidth="10"
                  fill="none"
                  strokeMiterlimit="10"
                />
              </mask>
            </defs>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  id="maskReveal"
                  mask="url(#mask)"
                  className={`${styles.cls1} ${styles.borderCircleDetails}`}
                  d="M86.11 499.08a292 292 0 01-2-409.95h4.24a289 289 0 00.36 408.37l1.62 1.61zM495.84 499.09l1.63-1.62a289 289 0 00.37-408.36h4.23a292 292 0 01-2 410z"
                />
                <circle
                  className={styles.cirderCircle}
                  cx="293.11"
                  cy="293.11"
                  r="287.52"
                  stroke="#32c8ff"
                  strokeWidth="3"
                  fill="none"
                  strokeMiterlimit="10"
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment GameVideoData on Contentstack_homepage {
    gameVideoData: competition {
      titleParts: title
      description
      videos: video {
        ...ContentstackVideo
      }
    }
  }
`;
