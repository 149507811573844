import { gsap } from "gsap";
import { DrawSVGPlugin } from "../../../../vendor/gsap/DrawSVGPlugin.js";

gsap.registerPlugin(DrawSVGPlugin);

export const animateSVG = (element: HTMLDivElement) => {
  const tl = gsap.timeline({ defaults: { ease: "power1.out" } });

  if (!element.firstElementChild) return tl;

  const elements = [...element.firstElementChild.childNodes];

  const topVGMDecoration = elements[0];
  const bottomSVGMDecoration = elements[1];
  const borderSVGAsset = elements[2];

  tl.from(borderSVGAsset, { duration: 1.5, drawSVG: "0%" }, 0.5)
    .from(bottomSVGMDecoration, { duration: 0.25, autoAlpha: 0 }, "<+0.3")
    .from(topVGMDecoration, { duration: 0.25, autoAlpha: 0 }, "<+0.5");

  return tl;
};

export const animateCardFrames = (parentElements: Array<HTMLDivElement> | null) => {
  if (!parentElements) return;
  const tl = gsap.timeline({ paused: true });
  parentElements.forEach((card, index) => {
    const delay = index === 2 ? 0 * 0.5 : index * 0.25;
    tl.add(animateSVG(card), delay);
  });
  return tl;
};
