import { graphql, PageProps } from "gatsby";
import React, { FunctionComponent } from "react";
import { GameImageData } from "../components/block/GameImageData/GameImageData";
import { GameOverview } from "../components/block/GameOverview/GameOverview";
import { GameVideoData } from "../components/block/GameVideoData/GameVideoData";
import { News } from "../components/block/news/News/News";
import { RegistrationHero } from "../components/block/RegistrationHero/RegistrationHero";
import { ImageSlider } from "../components/content/slider/ImageSlider/ImageSlider";
import { ContentstackArticle } from "../data/types/Contentstack";
import { ContentstackHomepage } from "../data/types/ContentstackPages";
import pageData from '../json/page-data.json';
import { useIsMobile } from "../hooks/useIsMobile";

const setting = {
  color: {
    theme: "#000"
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help"
    }
  },
  contactForm: {
    fields: [
      { id: "description", prefill: { "*": "My pre-filled description" } }
    ]
  }
};

interface HomepageProps {
  allContentstackHomepage: { nodes: Array<ContentstackHomepage> };
  allContentstackArticles: {
    articles: Array<ContentstackArticle>;
  };
}
const Homepage: FunctionComponent<PageProps<HomepageProps>> = ({
  // data: {
  //   allContentstackHomepage: {
  //     nodes: [{ registrationHero, newsletterSignup, news, gameOverview, gameImageData, gameVideoData, imageSlider }],
  //   },
  //   allContentstackArticles: { articles },
  // },
}) => {
  const isMobile = useIsMobile();
  const {
    allContentstackHomepage: {
      nodes: [{ registrationHero, newsletterSignup, news, gameOverview, gameImageData, gameVideoData, imageSlider }],
    },
    allContentstackArticles: { articles },
  } = pageData.result.data
  return (<>
    <img style={{ width: `${isMobile ? "20vw" : "8vw"}`, position: "absolute", top: "1vw", left: "2vw", zIndex: 2 }} src="https://lor.zing.vn/thoa-thuan-nguoi-dung_files/rule.jpg" alt="" title=""></img>
    <RegistrationHero
      videos={registrationHero.videos}
      tagline={registrationHero.tagline}
      subtitle={registrationHero.subtitle}
      logo={registrationHero.logo}
    />
    {/* <NewsletterSignup
      isActive={newsletterSignup.isActive}
      titleParts={newsletterSignup.titleParts}
      emailPlaceholder={newsletterSignup.emailPlaceholder}
      dobPlaceholder={newsletterSignup.dobPlaceholder}
      dobFormat={newsletterSignup.dobFormat}
      disclaimerText={newsletterSignup.disclaimerText}
      ctaText={newsletterSignup.ctaText}
      successMessage={newsletterSignup.successMessage}
      errorMessages={newsletterSignup.errorMessages}
    /> */}
    <News
      newsLetterSignUpIsActive={newsletterSignup.isActive}
      headline={news.headline}
      articles={articles}
      cta={news.cta}
      contentType={news.contentType}
      selectedArticleIds={news.selectedArticleIds}
      selectedCategories={news.selectedCategories}
      selectedTags={news.selectedTags}
    />
    <GameOverview
      titleParts={gameOverview.titleParts}
      content={gameOverview.content}
      cta={gameOverview.cta}
      videoId={gameOverview.videoId}
    />
    <GameImageData
      titleParts={gameImageData.titleParts}
      description={gameImageData.description}
      images={gameImageData.images}
      cta={gameImageData.cta}
    />
    <GameVideoData
      titleParts={gameVideoData.titleParts}
      description={gameVideoData.description}
      videos={gameVideoData.videos}
    />
    <ImageSlider titleParts={imageSlider.titleParts} slides={imageSlider.slides} />
  </>
  )
};

export const query = graphql`
  query HomepageQuery {
    pageMetadata: pageMetaData {
      title
      description
      image_url
    }
  }
`;

export default Homepage;
