import { gsap, Expo } from "gsap";
import DrawSVGPlugin from "../../../vendor/gsap/DrawSVGPlugin.js";

gsap.registerPlugin(DrawSVGPlugin);

export const animateCircle = (container: HTMLDivElement | null) => {
  const animatedCircle = container?.lastChild;
  if (!animatedCircle) return;

  const circleElement = animatedCircle?.lastChild?.firstChild?.lastChild as SVGCircleElement;
  const circleDetailElement = animatedCircle?.lastChild?.firstChild?.firstChild as SVGPathElement;
  const whiteCircleMask = animatedCircle?.firstChild?.firstChild?.firstChild as SVGCircleElement;
  const tl = gsap.timeline({ paused: true });
  const rotationTL = gsap.timeline();

  rotationTL.to(circleDetailElement, { rotation: "+=360", duration: 6, repeat: -1, ease: "none" });

  tl.set(whiteCircleMask, { rotation: 46, drawSVG: "0%", transformOrigin: "center" })
    .set(circleDetailElement, { rotation: -45, transformOrigin: "center" })
    .fromTo(circleElement, { drawSVG: "0%" }, { drawSVG: "100%", duration: 2.5, ease: Expo.easeInOut }, 0)
    .to(whiteCircleMask, { rotation: -45, drawSVG: "45%", duration: 1.4, ease: "none" }, 0.6)
    .to(whiteCircleMask, { drawSVG: "100%", duration: 0.8, onComplete: () => tl.timeScale(0.5) }, 1.8)
    .add(rotationTL, 0.6);

  return tl;
};
