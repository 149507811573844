import { graphql } from "gatsby";
import React, { FunctionComponent, useMemo, useRef } from "react";
import { ContentType, ContentTypeDescription } from "../../../../data/enum/ContentType";
import { ContentstackArticle, ContentstackCTA, ContentstackOption } from "../../../../data/types/Contentstack";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { useScrollObserver } from "../../../../hooks/useScrollObserver";
import { NewsCard } from "../../../content/cards/NewsCard/NewsCard";
import { NewsSlider } from "../../../content/slider/NewsSlider/NewsSlider";
import LinkButton from "../../../general/button/LinkButton/LinkButton";
import { animateNews } from "./animations";
import styles from "./News.module.scss";

export interface NewsProps {
  headline: string;
  articles: Array<ContentstackArticle>;
  contentType: ContentTypeDescription;
  selectedTags: Array<ContentstackOption>;
  selectedCategories: Array<ContentstackOption>;
  selectedArticleIds: Array<{ id: string }>;
  newsLetterSignUpIsActive: boolean;
  cta: ContentstackCTA;
}

export const News: FunctionComponent<NewsProps> = ({
  headline,
  articles,
  cta,
  contentType,
  selectedTags: [selectedTag],
  selectedCategories: [selectedCategory],
  selectedArticleIds,
  newsLetterSignUpIsActive,
}) => {
  const isMobile = useIsMobile();
  const desktopCardsWrapperRef = useRef<HTMLUListElement | null>(null);

  const observerRef = useScrollObserver<HTMLUListElement | null>(animateNews, desktopCardsWrapperRef, {
    triggerOnce: true,
  });

  const filteredArticles = useMemo(() => {
    switch (contentType) {
      case ContentType.SPECIFIC:
        const selectedIds = selectedArticleIds.map((articleId) => articleId.id);
        return articles.filter((article) => selectedIds.includes(article.id));
      case ContentType.BY_TAG:
        return articles
          .filter((article) => {
            const articleTags = article.tags.map((tag) => tag.title);
            return articleTags.includes(selectedTag.title);
          })
          .slice(0, 3);
      case ContentType.BY_CATEGORY:
        return articles
          .filter((article) => {
            const articleCategories = article.categories.map((category) => category.title);
            return articleCategories.includes(selectedCategory.title);
          })
          .slice(0, 3);
      default:
        // MOST_RECENT
        return articles.slice(0, 3);
    }
  }, [articles, contentType, selectedCategory, selectedTag, selectedArticleIds]);

  return (
    <section className={`${styles.news} ${newsLetterSignUpIsActive ? styles.addPaddingTop : styles.addPaddingTop}`}>
      <div className="container">
        <div className={"content-wrapper light"}>
          <h3 className={`heading-02 ${styles.headline}`}>{headline}</h3>
          {!isMobile && <LinkButton className={` ${styles.goToNewsPageButton}`} href={cta.href} label={cta.title} />}
        </div>
      </div>
      {isMobile && filteredArticles.length > 0 ? (
        <NewsSlider slides={filteredArticles} />
      ) : (
        <div className={`container ${styles.sliderWrapper}`} ref={observerRef}>
          <ul className={`content-wrapper light ${styles.contentWrapper}`} ref={desktopCardsWrapperRef}>
            {filteredArticles.map((slide, index) => (
              <li key={index} className={styles.contentItem}>
                <NewsCard cardContent={slide} animationDelay={index * 0.4} />
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="container">
        <div className={"content-wrapper light"}>
          {isMobile && <LinkButton className={` ${styles.goToNewsPageButton}`} href={cta.href} label={cta.title} />}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment News on Contentstack_homepage {
    news {
      headline
      contentType: content_type
      selectedTags: tag_select {
        title
      }
      selectedCategories: category_select {
        title
      }
      selectedArticleIds: articles_select {
        id
      }
      cta {
        title
        href
      }
    }
  }
`;
