import { graphql } from "gatsby";
import React, { useRef, useEffect } from "react";
import { ColorTheme } from "../../../../data/enum/ColorTheme";
import { ContentstackSlide, ContentstackAsset } from "../../../../data/types/Contentstack";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import DetailLine from "../../../general/borders/DetailLine/DetailLine";
import Icon from "../../../general/Icon/Icon";
import SectionTitle from "../../../general/SectionTitle/SectionTitle";
import Volt from "../../../general/Volt/Volt";
import { InfiniteSlider } from "../base/InfiniteSlider/InfiniteSlider";
import styles from "./ImageSlider.module.scss";
import { useScrollObserver } from "../../../../hooks/useScrollObserver";

import { animateCardFrames } from "./animation";

type imageSlideProps = {
  image?: ContentstackAsset;
  description: string;
  iconsRef: React.MutableRefObject<Array<HTMLDivElement | null>>;
  index: number;
};

export interface ImageSliderProps {
  titleParts: Array<string>;
  slides: Array<ContentstackSlide>;
}

const ImageSlide: React.FC<imageSlideProps> = ({ image, description, iconsRef, index }) => {
  const iconRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!iconRef.current || !iconsRef.current) return;
    iconsRef.current[index] = iconRef.current;
  }, [index, iconsRef]);

  return (
    <>
      {image && <img src={image?.url} alt="image" />}
      <div className={styles.copyWrapper}>
        <Icon innerRef={iconRef} className={styles.svgFrame} name="SlideFrame" />

        <p className={`copy-01 ${styles.sliderCopy}`}>{description}</p>
      </div>
    </>
  );
};

export const ImageSlider: React.FC<ImageSliderProps> = ({ titleParts, slides }) => {
  const isMobile = useIsMobile();
  const iconsRef = useRef<Array<HTMLDivElement>>([]);
  const observerRef = useScrollObserver<Array<HTMLDivElement>>(animateCardFrames, iconsRef, { triggerOnce: true });

  return (
    <section className={`${isMobile ? styles.mobileContainer : "container"} ${styles.imageSlider}`}>
      <Volt className={styles.topVolt} name={"VoltTop01"} position="top" />
      <div className={isMobile ? "container" : ""}>
        <div className={`content-wrapper light ${styles.titleWrapper}`}>
          <DetailLine className={styles.detailLine} theme={ColorTheme.LIGHT} variation="halfLeft">
            <SectionTitle className={styles.title} theme={ColorTheme.LIGHT} titleParts={titleParts} />
          </DetailLine>
        </div>
      </div>
      {isMobile ? (
        <InfiniteSlider className={styles.mobileSlider} slides={slides} />
      ) : (
        <div ref={observerRef} className={"content-wrapper"}>
          <ul className={styles.slider}>
            {slides.map((slide, index) => (
              <li key={slide.image?.url} className={styles.sliderElementWrapper}>
                <ImageSlide image={slide.image} description={slide.description} index={index} iconsRef={iconsRef} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </section>
  );
};

export const query = graphql`
  fragment ImageSlider on Contentstack_homepage {
    imageSlider: ecosystem {
      titleParts: title
      slides: featured_content {
        image {
          ...ContentstackAsset
        }
        description
      }
    }
  }
`;
