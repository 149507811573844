import { gsap, Expo } from "gsap";
import { SplitText } from "../../../vendor/gsap/SplitText.js";

export const animateChampions = (championsImages: Array<Element>) => {
  const tl = gsap.timeline({ repeat: -1 });

  championsImages?.forEach((champion) => {
    tl.from(champion, { autoAlpha: 0, xPercent: "-=12", duration: 1.6, ease: Expo.easeOut }).to(
      champion,
      { autoAlpha: 0, xPercent: "+=6", duration: 0.8, ease: Expo.easeInOut },
      "+=2.5",
    );
  });

  return tl;
};

export const animateDescription = (description: HTMLParagraphElement) => {
  const tl = gsap.timeline();

  const splitDescription = new SplitText(description, {
    type: "lines, words",
  });

  const height = (splitDescription.lines[0] as HTMLDivElement).getBoundingClientRect().height;

  tl.set(splitDescription.lines, { overflow: "hidden" });
  tl.from(splitDescription.words, {
    y: height,
    opacity: 0,
    duration: 0.5,
    stagger: 0.02,
    ease: "expo.out",
    onComplete: () => {
      splitDescription.revert();
    },
  });

  return tl;
};
