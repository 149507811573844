import { gsap, Power1 } from "gsap";

export const animateNews = (newsWrapperRef: HTMLUListElement | HTMLDivElement | null) => {
  if (!newsWrapperRef) return;

  const tl = gsap.timeline({ paused: true });

  tl.from(newsWrapperRef.children, {
    y: 30,
    duration: 1,
    autoAlpha: 0,
    stagger: 0.3,
    ease: Power1.easeOut,
  });

  return tl;
};
