import React, { FunctionComponent } from "react";
import styles from "./Slider.module.scss";
import Icon from "../../../../general/Icon/Icon";
import SecondaryButton from "../../../../general/button/SecondaryButton/SecondaryButton";
import { ContentstackSlide } from "../../../../../data/types/Contentstack";
import { useSlider } from "../../../../../hooks/useSlider";

interface SliderProps {
  slides: Array<ContentstackSlide>;
  className?: string;
}

export const Slider: FunctionComponent<SliderProps> = ({ slides, className = "" }) => {
  const {
    sliderRef,
    actions: { goToNext, goToPrevious },
    data: { activeSlideIndex, isPrevButtonEnabled, isNextButtonEnabled, scrollSnaps },
  } = useSlider();

  return (
    <div className={`${styles.slider} ${className}`}>
      <div className={styles.header}>
        {slides[activeSlideIndex].icon ? (
          <div className={styles.titleWrapper}>
            <img
              className={styles.titleIcon}
              alt={`${slides[activeSlideIndex].title?.toLowerCase()} icon`}
              src={slides[activeSlideIndex].icon?.url}
            />
            <h4 className={`heading-04 font-normal ${styles.title}`}>{slides[activeSlideIndex].title}</h4>
          </div>
        ) : (
          <img src={slides[activeSlideIndex].image?.url} />
        )}
        <div className={styles.sliderControlsWrapper}>
          <SecondaryButton
            className={`${styles.sliderButton} ${styles.prevButton} ${!isPrevButtonEnabled ? styles.disabled : ""}`}
            onClick={goToPrevious}
            icon="Chevron"
            ariaLabel="previous"
          />
          <Icon className={styles.ellipseIcon} name="Ellipse" />
          <SecondaryButton
            className={`${styles.sliderButton} ${styles.nextButton} ${!isNextButtonEnabled ? styles.disabled : ""}`}
            onClick={goToNext}
            icon="Chevron"
            ariaLabel="next"
          />
        </div>
      </div>
      <div className={styles.contentWrapper} ref={sliderRef}>
        <ul className={styles.content}>
          {(slides as Array<ContentstackSlide>).map((slide, index: number) => (
            <li key={index} className={`copy-01 ${styles.slide}`}>
              {slide.description}
            </li>
          ))}
        </ul>
      </div>
      <ul className={styles.bullets}>
        {scrollSnaps.map((_, index: number) => (
          <li key={index} className={`${styles.bulletWrapper} ${activeSlideIndex === index && styles.active}`}>
            <Icon className={styles.bulletIcon} name="Diamond" />
          </li>
        ))}
      </ul>
    </div>
  );
};
