import React, { useRef, useCallback } from "react";
import styles from "./RegistrationHero.module.scss";
import useGlobal from "../../../store";
import PrimaryButton from "../../general/button/PrimaryButton/PrimaryButton";
import { Video } from "../../general/Video/Video";
import { ContentstackVideo, ContentstackAsset } from "../../../data/types/Contentstack";
import { graphql } from "gatsby";
import { InteractionId, trackInteraction, trackEvent } from "../../../utils/analytics";
import btn_galaxy from "../../../assets/images/GalaxyStore_Vietnamese.png";
import { useIsMobile } from "../../../hooks/useIsMobile";

export interface RegistrationHeroProps {
  videos: Array<ContentstackVideo>;
  tagline: string;
  subtitle?: string;
  logo: ContentstackAsset;
}

export const RegistrationHero: React.FC<RegistrationHeroProps> = ({
  tagline,
  logo,
  subtitle,
  videos: [
    {
      video: {
        file: { url: videoUrl },
      },
      posterImage: { url: videoPosterUrl },
    },
  ],
}) => {
  const isMobile = useIsMobile();
  const [
    {
      appStoreSection: { appStoreData, ctaText, ctaEnabled },
    },
    { setRegistrationModalActiveState },
  ] = useGlobal();

  const rootRef = useRef<HTMLDivElement>(null);
  const mainLogoRef = useRef<HTMLImageElement>(null);
  const taglineRef = useRef<HTMLHeadingElement>(null);
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const appStoreWrapperRef = useRef<HTMLDivElement>(null);

  const onRegistrationButtonClick = useCallback(() => {
    trackEvent("cbRegistration");
    setRegistrationModalActiveState(true);
  }, [setRegistrationModalActiveState]);

  const onAppleButtonClick = useCallback(() => {
    trackInteraction(InteractionId.HERO.APPLE);
  }, []);

  const onGoogleButtonClick = useCallback(() => {
    trackInteraction(InteractionId.HERO.GOOGLE);
  }, []);

  const onGalaxyButtonClick = useCallback(() => {
    trackInteraction(InteractionId.HERO.GALAXY);
  }, []);

  return (
    <section ref={rootRef} className={styles.registrationHero}>
      <span className={styles.croppedCorner} />
      <span className={styles.dashDetail} />
      <Video className={styles.heroVideo} source={{ mp4Url: videoUrl, posterUrl: videoPosterUrl }} />
      <div className="container">
        <div className={`content-wrapper ${styles.contentWrapper}`}>
          {subtitle && <h4 className={` label-01 font-normal ${styles.subtitle}`}>{subtitle}</h4>}
          <img className={styles.mainLogo} src={logo.url} ref={mainLogoRef} />
          <h3 className={`heading-03 ${styles.tagline} ${!ctaEnabled ? styles.noCTA : ""}`} ref={taglineRef}>
            {tagline}
          </h3>
          {ctaEnabled && (
            <div ref={buttonContainerRef}>
              <PrimaryButton className={styles.registerButton} onClick={onRegistrationButtonClick} label={ctaText} />
            </div>
          )}
          <div className={styles.appStoreWrapper} ref={appStoreWrapperRef}>
            <h4 className={`heading-03 ${styles.availableText}`}>{appStoreData.title}</h4>
            <div className={styles.storesWrapper}>
              {appStoreData.isGoogleEnabled ? (
                <>
                  <a
                    id="taigame"
                    className={styles.appButton}
                    href={appStoreData.googleStoreUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onGoogleButtonClick}
                  >
                    <img src={appStoreData.googleStoreBadge.url} />
                  </a>
                </>
              ) : (
                <div className={`${styles.appButton} ${styles.disabled}`}>
                  <img src={appStoreData.googleStoreBadge.url} />
                </div>
              )}
              {appStoreData.isAppleEnabled ? (
                <>
                  <a
                    id="taigame"
                    className={styles.appButton}
                    href={appStoreData.appleStoreUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onAppleButtonClick}
                  >
                    <img src={appStoreData.appleStoreBadge.url} />
                  </a>
                </>
              ) : (
                <div className={`${styles.appButton} ${styles.disabled}`}>
                  <img src={appStoreData.appleStoreBadge.url} />
                </div>
              )}
              {!isMobile && <a
                id="taigame"
                className={styles.appButton}
                href="https://galaxystore.samsung.com/detail/com.riotgames.smg.league.wildriftvn"
                target="_blank"
                rel="noopener noreferrer"
                onClick={onGalaxyButtonClick}
              >
                <img src={btn_galaxy} />
              </a>}

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
      fragment RegistrationHero on Contentstack_homepage {
        registrationHero: header {
        tagline
      subtitle
      videos: hero_video {
        ...ContentstackVideo
      }
      logo: logo_image {
        ...ContentstackAsset
      }
    }
  }
      `;
