import { gsap } from "gsap";
import React, { FunctionComponent, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import { ContentstackAsset, ContentstackCTA } from "../../../data/types/Contentstack";
import styles from "./GameImageData.module.scss";
import DetailLine from "../../general/borders/DetailLine/DetailLine";
import SectionTitle from "../../general/SectionTitle/SectionTitle";
import Volt from "../../general/Volt/Volt";
import { animateChampions, animateDescription } from "./animations";
import TitleCopyWrapper from "../../general/borders/TitleCopyWrapper/TitleCopyWrapper";
import PrimaryButton from "../../general/button/PrimaryButton/PrimaryButton";
import LinkButton from "../../general/button/LinkButton/LinkButton";
import { ButtonColorTheme, ColorTheme } from "../../../data/enum/ColorTheme";
import { useIsMobile } from "../../../hooks/useIsMobile";

export interface GameImageDataProps {
  titleParts: Array<string>;
  description: string;
  images: Array<ContentstackAsset>;
  cta: Array<ContentstackCTA>;
}

export const GameImageData: FunctionComponent<GameImageDataProps> = ({ titleParts, description, images, cta }) => {
  const championsWrapperRef = useRef<HTMLUListElement | null>(null);
  const descriptionRef = useRef<HTMLParagraphElement | null>(null);
  const isMobile = useIsMobile();
  useEffect(() => {
    if (!championsWrapperRef.current) return;

    const championsImages = [...championsWrapperRef.current.children];
    const tl = gsap.timeline();
    tl.add(animateChampions(championsImages), 0);
    descriptionRef.current && tl.add(animateDescription(descriptionRef.current), 0.5);
  }, []);

  return (
    <section className={`container ${styles.gameImageData}`}>
      <Volt className={styles.topVolt} name={"VoltTop02"} position="top" />
      <Volt className={styles.mobileTopVolt} name={"VoltTop03"} position="top" />
      <div className={`content-wrapper light ${styles.contentWrapper}`}>
        <div className={styles.titleCopyWrapper}>
          <TitleCopyWrapper className={styles.copyWrapper}>
            <DetailLine className={styles.detailLine} variation="left">
              <SectionTitle className={styles.title} titleParts={titleParts} />
            </DetailLine>
            <p ref={descriptionRef} className={`copy-01 ${styles.description}`}>
              {description}
            </p>
          </TitleCopyWrapper>
          {!isMobile && (
            <div className={styles.ctasWrapper}>
              {/* <PrimaryButton
                className={styles.findChampButton}
                label={cta[0].title}
                href={cta[0].href}
                theme={ColorTheme.DARK}
              /> */}
              {cta[1] && <LinkButton label={cta[1].title} href={cta[1].href} theme={ButtonColorTheme.WHITE} />}
            </div>
          )}
        </div>

        <ul ref={championsWrapperRef} className={styles.championWrapper}>
          {images.map((championImage) => (
            <li key={championImage.title} className={styles.championImage}>
              <img src={championImage.url} alt={championImage.title} />
            </li>
          ))}
        </ul>

        {isMobile && (
          <div className={styles.ctasWrapper}>
            {/* <PrimaryButton
              className={styles.findChampButton}
              label={cta[0].title}
              href={cta[0].href}
              theme={ColorTheme.DARK}
            /> */}
            {cta[1] && <LinkButton label={cta[1].title} href={cta[1].href} />}
          </div>
        )}
      </div>
      <Volt className={styles.bottomVolt} name={"VoltBottom03"} position="bottom" />
      <Volt className={styles.mobileBottomVolt} name={"VoltBottom04"} position="bottom" />
    </section>
  );
};

export const query = graphql`
  fragment GameImageData on Contentstack_homepage {
    gameImageData: champions {
      titleParts: title
      description
      images: image {
        ...ContentstackAsset
      }
      cta {
        title
        href
      }
    }
  }
`;
