import { graphql } from "gatsby";
import React, { FunctionComponent } from "react";
import { ColorTheme } from "../../../data/enum/ColorTheme";
import { ContentstackAccordionItem, ContentstackCTA, ContentstackSlide } from "../../../data/types/Contentstack";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { Accordion } from "../../content/Accordion/Accordion";
import { Slider } from "../../content/slider/base/Slider/Slider";
import DetailLine from "../../general/borders/DetailLine/DetailLine";
import { TwoSideBorder } from "../../general/borders/TwoSideBorder/TwoSideBorder";
import PrimaryButton from "../../general/button/PrimaryButton/PrimaryButton";
import SectionTitle from "../../general/SectionTitle/SectionTitle";
import Volt from "../../general/Volt/Volt";
import YoutubeVideo from "../../general/YoutubeVideo/YoutubeVideo";
import styles from "./GameOverview.module.scss";

export interface GameOverviewProps {
  titleParts: Array<string>;
  content: Array<ContentstackAccordionItem> | Array<ContentstackSlide>;
  cta: ContentstackCTA;
  videoId: string;
}

export const GameOverview: FunctionComponent<GameOverviewProps> = ({ titleParts, content, cta, videoId }) => {
  const isMobile = useIsMobile();

  return (
    <section className={`container ${styles.gameOverview}`}>
      <Volt className={styles.topVolt} name={"VoltTop01"} position="top" />
      <div className={`content-wrapper ${styles.contentWrapper}`}>
        {isMobile && (
          <DetailLine className={styles.detailLine} variation="halfLeft">
            <SectionTitle titleParts={titleParts} />
          </DetailLine>
        )}
        <div className={styles.sectionContent}>
          {isMobile ? (
            <Slider className={styles.slider} slides={content as Array<ContentstackSlide>} />
          ) : (
              <div className={styles.copyWrapper}>
                <DetailLine className={styles.detailLine} variation="halfLeft">
                  <SectionTitle titleParts={titleParts} />
                </DetailLine>
                <div className={styles.accordionWrapper}>
                  <Accordion className={styles.accordion} items={content as Array<ContentstackAccordionItem>} />
                </div>
              </div>
            )}
          <div className={styles.videoWrapper}>
            {videoId && <YoutubeVideo className={styles.videoContainer} videoId={videoId} />}

            <TwoSideBorder
              className={styles.videoBorder}
              theme={isMobile ? ColorTheme.DARK : ColorTheme.LIGHT}
              position={isMobile ? "bottomRight" : "bottomLeft"}
            />
          </div>
        </div>
        {/* <PrimaryButton className={styles.learnMoreCTA} label={cta.title} href={cta.href} theme={ColorTheme.DARK} /> */}
      </div>
      <Volt className={styles.bottomVolt} name={"VoltBottom02"} position="bottom" />
    </section>
  );
};

export const query = graphql`
  fragment GameOverview on Contentstack_homepage {
    gameOverview: introduction {
      titleParts: title
      content: accordion {
        icon {
          ...ContentstackAsset
        }
        title
        description
      }
      cta {
        title
        href
      }
      videoId: video_id
    }
  }
`;
